import React from 'react';
import {Link} from "react-router-dom";

const NavBar = () => (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
            <a className="navbar-brand" href="/" style={{marginLeft: '15px'}}>
                <img src="/assets/datamatrix_logo.svg" alt="logo" style={{height: '40px'}}/>
            </a>
            <div className="">
                    <span className="navbar-text font-weight-bold">
                        CPIV3@datamatrix
                    </span>
            </div>
            <div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link" aria-current="page" to="/">Assistant</Link>
                    </li>
                    <li className="nav-item" >
                        <Link className="nav-link" aria-current="page" to="/kb">KnowledgeBase</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);

export default NavBar;