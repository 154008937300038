import React, {useEffect, useState} from 'react';
import {CloudCheckFill} from "react-bootstrap-icons";


const API_KEY = "https://cpiv3.hammerdigital.at"
// const API_KEY = "http://localhost:8080"

const KnowledgeBase = () => {
    const [activeTab, setActiveTab] = useState('sync');
    const [syncStates, setSyncStates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const fetchSyncStates =  async (page = currentPage) => {
        if((currentPage >= 0 && currentPage <= totalPages) || syncStates.length === 0) {
            const url = `${API_KEY}/api/sync/states?page=${currentPage - 1}&pageSize=${itemsPerPage}`;
            const requestOptions = {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            };

            return await fetch(url, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setSyncStates(data.items);
                    setTotalItems(data.length);
                    return data.items
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    };

    useEffect(() => {
        fetchSyncStates().then();
    }, [currentPage, itemsPerPage]);

    const handlePageChange = (newPage) => {
        if(newPage >= 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const openOwncloud = () => {
        window.open("https://owncloud.hd11.dev/s/Z17YSoV2EKXx7aR")
    }

    const startSync = () => {
        const url = `${API_KEY}/api/sync/manuals`;
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };

        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const intervalId = setInterval(() => {
                    fetchSyncStates().then((data) => {
                        if(data === undefined) clearInterval(intervalId)

                        const allFinished = data.every(state => state.state === 'Finished');
                        if (allFinished) {
                            clearInterval(intervalId);
                        }
                    });
                }, 2000);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleString('de-DE', options);
    };

    return (
        <div>
            <ul className="nav nav-tabs pt-3 px-5">
                <li className="nav-item" key="manual">
                    <a
                        className={`nav-link ${activeTab === 'manual' ? 'active' : ''}`}
                    >
                        Manuals
                    </a>
                </li>
                <li className="nav-item" key="kb">
                    <a
                        className={`nav-link ${activeTab === 'kb' ? 'active' : ''}`}
                    >
                        Wissensblöcke
                    </a>
                </li>
                <li className="nav-item" key="sync">
                    <a
                        className={`nav-link ${activeTab === 'sync' ? 'active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleTabClick('sync');
                        }}
                    >
                        Sync
                    </a>
                </li>

            </ul>
            {activeTab === 'sync' ?
                <div className="p-4">
                    <button type="button" className="btn btn-primary mb-2" onClick={startSync}>Sync starten</button>
                    <button type="button" color="blue" className="btn btn-outline-primary mb-2 mx-2"
                            onClick={openOwncloud}>Upload word
                    </button>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col" width="40px" className="text-center"></th>
                            <th scope="col" width="150px">Datum</th>
                            <th scope="col" width="150px">Status</th>
                            <th scope="col">Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        {syncStates.map((state, index) => (
                            <tr key={index}>
                                <th scope="row" className="text-center">
                                    {state.state !== 'Finished' ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        : <CloudCheckFill color="green"></CloudCheckFill>
                                    }
                                </th>
                                <td>{formatDate(state.createdAt)}</td>
                                <th scope="row">
                                    {state.state}
                                </th>
                                <th>{state.infoMessage}</th>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <li className="page-item" onClick={() => handlePageChange(currentPage - 1)}>
                                <a className="page-link" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            {Array.from({length: totalPages}, (_, i) => (
                                <li className="page-item" key={i} onClick={() => handlePageChange(i + 1)}>
                                    <a className="page-link" href="#">{i + 1}</a>
                                </li>
                                ))}
                            <li className="page-item" onClick={() => handlePageChange(currentPage + 1)}>
                                <a className="page-link" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                : <div>Under construction</div>
            }
        </div>
    );
};

export default KnowledgeBase;