import './App.css';
import React from "react";
import 'react-markdown-editor-lite/lib/index.css';
import NavBar from "./components/NavBar";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Assistant from "./pages/Assistant";
import KnowledgeBase from "./pages/KnowledgeBase";

function App() {
  return (
    <BrowserRouter>
        <NavBar />
        <Routes>
            <Route path="/" element={<Assistant />} />
            <Route path="/kb" element={<KnowledgeBase />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
