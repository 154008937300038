import React, { useState } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';

const KnowledgeBlockCard = ({ knowledgeBlock }) => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <>
            <Card
                style={{ width: '200px', height: '200px', margin: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '10px', backgroundColor: '#f9f9f9', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
                onClick={handleShow}
            >
                <Card.Body className="d-flex flex-column justify-content-start align-items-start" style={{ gap: '10px', width: '100%', height: '100%', padding: 0 }}>
                    <Card.Text style={{ textAlign: 'left', width: '100%' }}>
                        {knowledgeBlock.name.substring(0, 100)}...
                    </Card.Text>
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Detailansicht</Modal.Title>
                </Modal.Header>
                <Modal.Body>{knowledgeBlock.name}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default KnowledgeBlockCard;
